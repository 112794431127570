<template>
  <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <div class="container" id="custom-cards">
      <div class="row row-cols-1 row-cols-lg-8 align-items-stretch g-4 py-5">
        <div class="col">
          <h3 class="pb-2 border-bottom text-dark-blue">Url</h3>
          <div class="container">
            <div class="row">  
              <div class="col-md-6 mb-4" align="left">
                <label for="project" class="form-label">Date</label>
                <v-date-picker v-model="GetUrlPost.date" mode="Date" :model-config="modelConfig" @dayclick="GetUrls()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <input class="form-control" :value="inputValue" v-on="inputEvents" />
                  </template>
                </v-date-picker>
              </div>
              <div class="col-xs-12 col-md-6 mb-4" align="left">
                <label for="member" class="form-label">Member</label>
                <Multiselect v-model="MultiSelectMembers.value" v-bind="MultiSelectMembers" class="size-multiselect" @select="GetUrls()" >
                  <template v-slot:tag="{ option, handleTagRemove, disabled }">
                    <div class="multiselect-tag is-user">
                      <img :src="option.icon">
                      <a :style="{'color':option.color}">{{ option.label }}</a>
                      <span v-if="!disabled" class="multiselect-tag-remove" @mousedown.prevent="handleTagRemove(option, $event)">
                        <span class="multiselect-tag-remove-icon"></span>
                      </span>
                    </div>
                  </template>
                  <template v-slot:option="{ option }">
                    <img class="character-option-icon" :src="option.icon"> {{ option.label }}
                  </template>
                </Multiselect>
              </div>
            </div>
          </div> 
          <div v-if="ListUrls.status != 'false'" class="card card-cover overflow-hidden text-white bg-white rounded-5 shadow-lg">
            <div class="d-flex flex-column  p-4 pb-3 text-blue text-shadow-1">
              <div style="display:inline !important"></div>
              <!-- Tabla-->
              <div class="table-responsive">
                <table id="url-table" class="table table-striped responsive">
                  <thead>
                    <tr class="text-start">
                      <th scope="col">
                        <span class="text-blue">Browser</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Title</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">URL</span>
                      </th>
                      <th scope="col">
                        <span class="text-blue">Time spent</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody class="mb-special">
                    <tr class="text-start" v-if="ListUrls.status != 'false'" v-for="(obj, key, index) in ListUrls.list" :key="index">
                      <td class="text-start">
                        {{obj.browser}}
                      </td>
                      <td class="text-start">{{obj.title_page}}</td>
                      <td v-if="obj.url != 'no-url'" class="text-start">
                        <a target="_blank" :href="'https://'+obj.url">{{obj.domain}}</a>
                      </td>
                      <td v-else class="text-start">{{obj.domain}}</td>
                      <td class="text-start">{{obj.seconds}}</td>
                    </tr>
                  </tbody>
                </table>
                <!-- Paginador -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  import Api from '@/services/Request';
  import "datatables.net-dt/js/dataTables.dataTables"
  import "datatables.net-dt/css/jquery.dataTables.min.css"
  import Multiselect from '@vueform/multiselect';
  import $ from 'jquery';
  import moment from 'moment-timezone'

  export default 
  {
    name: 'Url',
    components: 
    {
      Multiselect
    },
    data: () => (
    {
      MultiSelectMembers: 
      {
        value: [],
        closeOnSelect: true,
        options: [],
        searchable: true,
        createTag: false,
        inputType: "email",
        required: false,
        placeholder: "example@domain.com",
        hideSelected: true,
        showOptions: true
      },
      ListMembers: 
      {
        status: "false",
        list: {}
      },
      GetUrlPost: 
      {
        date: "",
        id_session: "",
        timezone: moment.tz.guess(true)
      },
      modelConfig: 
      {
        type: 'string',
        mask: 'YYYY-MM-DD', 
      },
      CurrentDate: 
      {
        date: "",
        timezone: moment.tz.guess(true)
      },
      ListUrls: 
      {
        status: "false",
        list: {}
      }
    }),
    methods: 
    {
      ActualDate()
      {
        let date = "";
        let year = new Date().getFullYear();
        let month = new Date().getMonth() + 1;
        let  month2 =  month>=10?month:'0'+ month;
        let day = new Date().getDate();
        date = year+"-"+month2+"-"+day;
        return date;
      },
      GetUrls() 
      {
        if(this.MultiSelectMembers.value != "")
        {
          this.GetUrlPost.id_session = this.MultiSelectMembers.value

          Api.post("/url/list", this.GetUrlPost).then((result) => 
          {
            this.ListUrls.status = result.data.status

            if (this.ListUrls.status == "true") 
            {
              this.ListUrls.list = result.data.url;
              this.$nextTick(() => 
              {
                this.InitDatatable()
              })
            }
          })
        }
      },
      GetCurrentDate() 
      {
        var currentDateWithFormat = this.ActualDate();//new Date().toJSON().slice(0, 10).replace(/-/g, '-');
        this.GetUrlPost.date = currentDateWithFormat
      },
      InitDatatable() 
      {
        var table = $('#url-table').DataTable(
        {
          dom: 'lftip',
          //dom: 'Blftip',
          responsive: true,
          destroy: true,
          lengthChange: false,
          aaSorting: [ [0,'asc'] ]
        });
      },
      GetMembers()  
      {
        Api.post("/member/list/", "").then((result) => 
        {
          this.ListMembers.status = result.data.status

          if (this.ListMembers.status == "true") 
          {
            this.ListMembers.list = result.data.member

            for (var i = 0; i < this.ListMembers.list.length; i++) 
            {
              if (this.ListMembers.list[i].description == "Worker") 
              {
                var options = 
                {
                  value: this.ListMembers.list[i].id_session,
                  label: this.ListMembers.list[i].email,
                  icon: this.ListMembers.list[i].picture,
                  color: this.ListMembers.list[i].color
                }
                this.MultiSelectMembers.options.push(options)
              }
            }
          }
        })
      },
    },
    mounted() 
    {
      this.GetCurrentDate()
      this.GetUrls()
      this.GetMembers()
    }
  }
</script>

<style scoped></style>
